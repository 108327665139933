//
// _fechas.scss
//

.fec-tooltip {
  position: relative;
  display: inline-block;
}

.fec-tooltip .fec-tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  line-height: 1.5;

  position: absolute;
  z-index: 99999;
  bottom: 125%; /* Posición del tooltip */
  left: 50%;
  margin-left: -150px; /* Centrar el tooltip */

  opacity: 0;
  transition: opacity 0.5s;
}

.feriado:has(.fec-bloqueo) {
  color: blue !important;
  
  &:hover {
    background-color: #6fb3f8 !important;
  }
}

.flatpickr-day:hover .fec-tooltip .fec-tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Flecha del tooltip */
.fec-tooltip .fec-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.fec-tooltiptext ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.flatpickr-innerContainer:has(.fec-tooltip){
  overflow: visible !important;

  .flatpickr-rContainer>.flatpickr-days {
    overflow: visible !important;
  }
}