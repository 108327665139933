//
// _encuestas.scss
//

.barra-preguntas{
  visibility: hidden;
  margin-top: -16px;
  margin-left: -16px;
  margin-right: -16px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-top: 2px;
  margin-bottom: -5px;
}

.card-preguntas:hover .barra-preguntas{
  visibility: visible;  
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.5s; /* Firefox < 16 */
        -ms-animation: fadein 0.5s; /* Internet Explorer */
         -o-animation: fadein 0.5s; /* Opera < 12.1 */
            animation: fadein 0.5s;
}

@keyframes fadein {
	from {
		opacity: 0;
		// transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		// transform: translate3d(0, 0, 0);
	}}