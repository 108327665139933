
[data-layout-mode="dark"] .form-select-fen {

  [class$="control"]{
      background-color: var(--vz-gray-200) !important;
      color: var(--#{$prefix}body-color);
  }
  
  [class$="option"] {
      color: var(--#{$prefix}body-color);
  }

  input {
      color: var(--#{$prefix}body-color) !important;
  }
}

.form-select-fen {
  [class$="control"]{
    min-height: 36px !important;
  }

  [class$="indicatorSeparator"] {
    margin-bottom: 7px !important;
    margin-top: 7px !important;
  }

  [class$="indicatorContainer"] {
    padding: 7px !important;
  }
}