.programa-matricula-selected {
  background-color: $accordion-button-active-bg !important;

  td {
    color: $accordion-button-active-color !important;
  }
}

[data-layout-mode="dark"] .programa-matricula-selected {
  td {
    color: $secondary !important;
    font-weight: 500;
  }
}

.financiamiento-descuento-no-vigente {
  td {
    --vz-text-opacity: 1;
    color: #878a99 !important;
  }
}

.expanded-row {
  animation: slideDown 0.3s ease forwards;
  transform-origin: top;
}

.expanded-row td {
  padding: 0 !important;
  background: transparent;
}

.expanded-row-content {
  background-color: #f8f9fa;
  padding: 1rem;
  animation: contentFade 0.3s ease forwards;
  transform-origin: top;
  opacity: 0;
}

@keyframes slideDown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 500px;
    opacity: 1;
  }
}

@keyframes contentFade {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}