//
// filepond
//

.filepond--root{
  margin-bottom: 0;

  &[data-style-panel-layout~=circle]{
    .filepond--drop-label label{
      font-size: 14px;
    }
  }
}

.filepond--panel-root{
  border: 2px dashed var(--#{$prefix}border-color);
  background: var(--#{$prefix}card-bg-custom);
}

.filepond--drop-label {
  color: var(--#{$prefix}body-color);
  label{
    font-weight: $font-weight-medium;
  }
}

.filepond--credits {
  display: none;
}

.filepond--item-panel {
  background-color: $primary !important;
}

// Para integrar filepond dentro de un formulario manteniendo el mismo alto que los inputs
.filepond-form-control {
  height: 38px !important;
  margin-bottom: 0 !important;

  .filepond--list {
    left: 0 !important;
    right: 0 !important;
  }

  .filepond--drop-label {
    min-height: 38px !important;
    height: 38px !important;
    transform: none !important;
    font-family: var(--vz-body-font-family) !important;
    @include font-size($input-font-size);
    color: $input-placeholder-color;
    overflow: hidden !important;
    text-overflow: clip !important;
  }

  .filepond--list-scroller {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    transform: none !important;
    mask: none !important;
  }

  .filepond--item {
    height: 38px !important;
    margin: 0 !important;
  }

  .filepond--file {
    padding: 5px 10px !important;
    border-radius: 4px !important;
  }

  .filepond--panel-root{
    border-radius: 8px !important;
    border: 2px dashed $input-border-color;
    background: var(--#{$prefix}card-bg-custom);
  }
}