//
// _programas.scss
//

.ck-editor__editable {
  min-height: 50px !important;
}

// Calendario.js

.cal-sesiones {
  padding: 0 !important;
}

// .cal-sesiones-ocultas {
//   padding: 0 !important;
//   transition: padding 0.5s ease-in-out;
// }

// .cal-sesiones-visible {
//   padding-left: 25px !important;
//   padding-top: 20px !important;
//   padding-bottom: 20px !important;
//   padding-right: 0px !important;
//   transition: padding-top 0.5s ease-in-out, 
//               padding-bottom 0.5s ease-in-out, 
//               padding-left 0.3s ease-in-out 0.2s;
// }

.cal-modulo-selected {
  td {
    color: $primary !important;
    background-color: rgba($primary, 0.2) !important;

    &:not(:has(.list-inline)){
      font-weight: bolder !important;
    }
  }
}

.cal-personalizado {
  transition: all 0.3s ease-in-out;
  // height: 0;
  min-height: 0;
  opacity: 0;
  overflow: hidden;
}

.cal-personalizado.slide-down {
  // height: 375px;
  opacity: 1;
}