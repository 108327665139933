.accordion-ficha-externa {
  $primary: #32518b;

  .form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
  }

  & * {
    --vz-btn-color: #fff;
    --vz-btn-bg: #32518b;
    --vz-btn-border-color: #32518b;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #2e497b;
    --vz-btn-hover-border-color: #2e497b;
    --vz-btn-focus-shadow-rgb: 102, 86, 190;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #2e497b;
    --vz-btn-active-border-color: #2e497b;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #fff;
    --vz-btn-disabled-bg: #32518b;
    --vz-btn-disabled-border-color: #32518b;
  }

  .filepond--item-panel {
    background-color: $primary !important;
  }

  .accordion-button:not(.collapsed) {
    background-color: #32518bd6;
    color: #fff;
  }

  .accordion-button {
    background-color: #91b5f878;
  }
}

.card-body-ficha-externa {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.valores-descuentos-ficha-externa{
  $primary: #32518b;

  .filepond--item-panel {
    background-color: $primary !important;
  }
}

@media only screen and (max-width: 760px) {
  .estudios-ficha-externa {
    table,
    table thead,
    table tbody,
    table th,
    table td,
    table tr {
      display: block;
    }

    thead {
      display: none !important;
      border-bottom: none !important;
    }

    tbody {
      border-top: none !important;
    }

    tfoot {
      display: none !important;
      border-bottom: none !important;
    }

    td {
      padding-top: 3px;
      padding-bottom: 3px;
      border: none !important;
    }

    tr {
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 10px;
      display: block;
      border: 1px solid #ccc;
      border-radius: 5px;

      td:first-child {
        padding-left: 0.6rem !important;
        border-top: none !important;
      }

      td:last-child {
        border-bottom: none !important;
      }
    }

    table tbody tr td:before {
      font-weight: bold;
      color: $text-muted;
      display: block;
    }
    table tbody tr td:nth-of-type(1):before {
      content: "Tipo Estudio";
    }
    table tbody tr td:nth-of-type(2):before {
      content: "Título o Grado";
    }
    table tbody tr td:nth-of-type(3):before {
      content: "Año";
    }
    table tbody tr td:nth-of-type(4):before {
      content: "País";
    }
    table tbody tr td:nth-of-type(5):before {
      content: "Institución";
    }
  }

  .valores-descuentos-ficha-externa {
    table,
    table thead,
    table tbody,
    table th,
    table td,
    table tr {
      display: block;
    }

    thead {
      display: none !important;
      border-bottom: none !important;
    }

    tbody {
      margin-top: 1rem;
      border-top: none !important;
    }

    tfoot {
      display: none !important;
      border-bottom: none !important;
    }

    td {
      padding-top: 3px;
      padding-bottom: 3px;
      border: none !important;
    }

    tr {
      margin-bottom: 10px;
      display: block;
      border: 1px solid #ccc;
      border-radius: 5px;

      td:first-child {
        padding-left: 0.6rem !important;
        border-top: none !important;
      }

      td:last-child {
        border-bottom: none !important;
      }
    }

    table tbody tr td:before {
      font-weight: bold;
      color: $text-muted;
      display: block;
    }
    table tbody tr td:nth-of-type(1):before {
      content: "Concepto Descuento";
    }
    table tbody tr td:nth-of-type(2):before {
      content: "% Descuento";
    }
    table tbody tr td:nth-of-type(3):before {
      content: "Monto Descuento";
    }
    table tbody tr td:nth-of-type(4):before {
      content: "Documento de Respaldo";
      margin-bottom: 3px;
    }
  }
}

.valores-descuentos-ficha-externa {
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  tr:last-child {
    td {
      border-bottom: none !important;
    }
  }

  td {
    vertical-align: middle;
  }

  & * {
    --vz-btn-color: #fff;
    --vz-btn-bg: #32518b;
    --vz-btn-border-color: #32518b;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #2e497b;
    --vz-btn-hover-border-color: #2e497b;
    --vz-btn-focus-shadow-rgb: 102, 86, 190;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #2e497b;
    --vz-btn-active-border-color: #2e497b;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #fff;
    --vz-btn-disabled-bg: #32518b;
    --vz-btn-disabled-border-color: #32518b;
  }
}

.bloquea-clic-ficha-externa {
  pointer-events: none;
}

.captura-clic-ficha-externa {
  pointer-events: auto;
  z-index: 99999999999;
}

.confirm-ficha-externa {
  $primary: #32518b;
  .form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
  }
}
