@import "../scss/icons.scss";
@import "../scss/config/material/bootstrap.scss";
@import "../scss/config/material/app.scss";
@import "../scss/config/material/custom.scss";
@import"./shepherd.css";
@import "./mermaid.min.css";

// @import "./components/demos.scss";

//RTL 
// @import "../scss/rtl/components-rtl";
// @import "../scss/rtl/layouts-rtl";
// @import "../scss/rtl/plugins-rtl";
// @import "../scss/rtl/pages-rtl";

.input-phone-number{
  input{
    outline: none !important;
    border:none !important;
    box-shadow: none !important;
  }
}

.w-select-mes {
  width: 130px !important;
}

.boton-adjunto-seguimiento{
  display: flex;
  justify-content: center;
}

.boton-adjunto-seguimiento .filename{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.feriado-calendario{
  background-color: #fc8585;
}

.feriado-calendario:hover{
  background-color: #fa7d7d;
}

.calendar{
  overflow-x: unset !important;
}

.calendario-home .flatpickr-days{
  border: none !important;
}

.calendario-home .eventos {
  position: relative;
  display: flex;
  align-items: center;
  justify-content:space-around;
  bottom: 8px;
}

.calendario-home .event {
  width: 3px;
  height: 3px;
  border-radius: 150px;
  content: " ";
  display: block;
  background: #3d8eb9;
}

.calendario-home .event.busy {
  background: #f64747;
}

.calendario-eventos .eventos {
  position: relative;
  display: flex;
  align-items: center;
  justify-content:space-around;
  bottom: 8px;
}

.flatpickr-day.feriado {
  color:#f64747;
  // background-color: #dd2e2e;
}
.flatpickr-day.feriado:hover {
  color:#f64747;
  background-color: #fc8585;
}

.calendario-eventos .event {
  width: 3px;
  height: 3px;
  border-radius: 150px;
  content: " ";
  display: block;
  background: #3d8eb9;
}

.calendario-eventos .event.busy {
  background: #f64747;
}

//Rigthsidebar offcanvas
.offcanvas-header-dark {
  .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}

body {
  * {
    outline: none;
  }
}
.noUi-horizontal .noUi-handle {
  height: 16px !important;
  width: 16px !important;
  border-radius: 50% !important;
  right: -10px !important;
  top: -7px !important;
}
.noUi-connect,
.noUi-horizontal {
  height: 2px !important;
  background: #4b38b3 !important;
}
.noUi-handle {
  background: #4b38b3 !important;
  border: 2px solid var(--vz-card-bg) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.noUi-target {
  background: #fafafa !important;
  border-radius: 4px !important;
  border: 1px solid #d3d3d3 !important;
  background-color: var(--vz-light) !important;
  border-color: var(--vz-light) !important;
  box-shadow: none !important;
  height: 4px !important;
}
.dropzone .dz-message {
  text-align: center;
}

// //Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}
.fc-event {
  border: none !important;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;
    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }
  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }
  .fc-today-button {
    display: none;
  }
}
.flatpickr-calendar.open {
  z-index: 1090 !important;
}
.upcoming-scheduled .flatpickr-input[readonly] {
  display: none;
}
.ql-container.ql-snow {
  height: 250px;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #405189 !important;
}

// .Toastify__toast{
//   min-height: 43px !important;
//   max-height: 43px !important;
// }

@each $color, $value in $theme-colors {
  .simplebar-track-#{$color} {
    .simplebar-scrollbar:before{
      background: $value !important; 
    }
  }   
}

// chat
.chat-conversation .chat-list .message-box-drop{
  .nav-btn{
      font-size: 18px;
      padding: 4px;
      color: $gray-600;
  }
}

// authentication

#tsparticles{
  canvas{
    position: absolute !important;
  }
}

.alert-msg-show {
  right: 24px;
  top: 40px;
  z-index: 1005;
}

// Emoji Picker

.pickerEmoji{
  position: absolute;
  bottom: -50px;
  left: 10%;
  transform: translateX(-50%);
}

// Toast
.Toastify {
  .Toastify__toast{
    min-height: 43px;
  }
  .Toastify__close-button {
    top: 6px;
  }
  .Toastify__close-button--light {
    color: $light;
  }
}

// Tranfer List

.rdl-filter, .rdl-control{
  background-color: $input-bg;
  border: 1px solid $input-border-color !important;
  color: $input-color !important;
}

.rdl-move{
  background: var(--#{$prefix}light) !important;
  border: 1px solid var(--#{$prefix}light) !important;
  color: var(--#{$prefix}body-color) !important;
}

.react-dual-listbox {
  input:disabled, select:disabled{
    background-color: $input-disabled-bg !important;
    border: 1px solid $input-border-color !important;
  }
}

.css-26l3qy-menu{
  background-color: var(--#{$prefix}gray-200) !important;
  border-color: var(--#{$prefix}border-color) !important;
}

.css-1n7v3ny-option{
  background-color: var(--#{$prefix}gray-300) !important;
  border-color: var(--#{$prefix}border-color) !important;
}

[data-filepond-item-state*='error'] .filepond--file,
[data-filepond-item-state*='invalid'] .filepond--file,
[data-filepond-item-state*='load-invalid'] .filepond--file {
    background-color:#dd2e2e;
}

.academicos-fondo > .react-dual-listbox {
  height: 400px;
}