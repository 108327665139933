/*
  Para estilo de input react-phone-number-input
*/

.input-phone-number {
  height: 38px;

  .PhoneInputInput {
    background: transparent;
    color: var(--#{$prefix}body-color);
  }

  .PhoneInputCountrySelect {
    background-color: var(--#{$prefix}light);
    
    option {
      background-color: var(--#{$prefix}light);
      color: var(--#{$prefix}body-color);
    }
  }
}