// Componente Historial

.historial-ver-mas {
  position: absolute;
  left: 2.3rem;
  margin-top: 25px !important;
  padding: 0px !important;
  padding-top: 1px !important;
  height: 15px !important;
  width: 15px !important;
}

.historial-content {
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}