.webpay-button-animate {
  animation: fadeInScale 0.3s ease-in-out;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.webpay-button-exit {
  animation: fadeOutScale 0.3s ease-in-out;
}

@keyframes fadeOutScale {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.boton-webpay {
  background-color: #eee;
  border: none;

  &:hover {
    filter: brightness(0.95);
  }
}

[data-layout-mode="dark"] .boton-webpay {
  background-color: #2C2E2F;
  border: none;

  &:hover {
    filter: brightness(1.2);
  }
}
