//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-semibold;
  }

  > thead {
    border-color: $table-border-color;
  }
  > :not(:first-child) {
    border-top-width: $table-border-width;
  }
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

// card table

.table-card {
  margin: (-$card-spacer-y) (-$card-spacer-x);

  th,
  td {
    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }
  }

  .table > :not(:first-child) {
    border-top-width: $table-border-width;
  }
}

@each $color, $value in $theme-colors {
  .border-#{$color} {
    &.table {
      > thead {
        border-color: $value !important;
      }
    }
  }
  .table-#{$color} {
    &.table {
      > thead {
        border-bottom-color: lighten($value, 10%) !important;
      }
    }
  }
}

.table {
  .form-check {
    padding-left: 0px;
    margin-bottom: 0px;
    .form-check-input {
      margin-left: 0px;
      margin-top: 0px;
      float: none;
      vertical-align: middle;
    }
  }
  .sort {
    position: relative;
    &::before {
      content: "\f035d";
      position: absolute;
      right: 0.5rem;
      top: 18px;
      font-size: 0.8rem;
      font-family: "Material Design Icons";
    }
    &::after {
      position: absolute;
      right: 0.5rem;
      content: "\f0360";
      font-family: "Material Design Icons";
      font-size: 0.8rem;
      top: 12px;
    }
  }
}

[data-layout-mode="dark"] {
  .table-light {
    background-color: var(--#{$variable-prefix}light);
    color: var(--#{$variable-prefix}body-color);
    th,
    td {
      background-color: var(--#{$variable-prefix}light);
    }
  }
}

.rdt_Table {
  &.eSTlnH {
    background-color: var(--#{$variable-prefix}card-bg) !important;
    color: var(--#{$variable-prefix}body-color);
  }

  .gZWIKX,
  .jVeafb {
    color: var(--#{$variable-prefix}body-color);
    background-color: var(--#{$variable-prefix}card-bg);
  }

  .jVeafb:not(:last-of-type),
  .gZWIKX {
    border-bottom-color: var(--#{$variable-prefix}border-color);
  }
}

.rdt_Pagination {
  &.jxflYm {
    background-color: var(--#{$variable-prefix}card-bg);
    color: var(--#{$variable-prefix}body-color);
    border-top-color: var(--#{$variable-prefix}border-color);
  }

  .bvxQGL {
    fill: var(--#{$variable-prefix}dark);
  }

  .bvxQGL:disabled {
    fill: rgba(var(--#{$variable-prefix}dark-rgb), 0.25);
  }
}

.draggable_td {
  color: #8b7ece !important;
}

.draggable_td:hover {
  cursor: move;
}

// @import '../../../node_modules/dragula/dist/dragula.min.css';
// .gu-mirror.gu-mirror {
//   position: fixed;
//   z-index: 9999;
//   opacity: 0.8;
// }

.gu-hide {
  display: none;
}

.gu-unselectable {
  user-select: none;
}

.gu-transit {
  opacity: 0.2;
}

.editable-cell-icon {
  position: relative;
  bottom: 1ex;
  margin-left: 0.5rem;
}

// TableContainer

// fila color info
.row-info {
  color: $info !important;
  &:hover {
    color: $info !important;
  }
}

.table-striped {
  > tbody > tr.row-info:nth-of-type(#{$table-striped-order}) > * {
    color: $info !important;
  }
}

.table-hover {
  > tbody > tr.row-info:hover > * {
    color: $info !important;
  }
}
// fin fila color info

// animacion de carga
@keyframes tablePulse {
  0% {
    opacity: 0.95;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.95;
  }
}

@keyframes progressBar {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.loading-table-pulse {
  animation: tablePulse 1.6s ease-in-out infinite;

  position: relative;
  
  &::before, &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, 
      transparent 0%,
      $info 50%,
      transparent 100%
    );
    background-size: 200% 100%;
    animation: progressBar 0.8s linear infinite;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}